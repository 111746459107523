import {Component} from 'react'
import colors from './colors'

const styles = {
    bubbleAlert: {
        backgroundColor: colors.black,
        color: colors.white,
        borderRadius: '50%',
        width: '2rem',
        height: '2rem',
        position: 'absolute',
        bottom: '-.5rem',
        left: '-.5rem',
        textAlign: 'center',
        padding: 0,
        lineHeight: '2rem',
        zIndex: 1,
    }
}

class BubbleAlert extends Component {
    getNumber(n) {
        if (!n) {
            return ''
        }

        return n > 9 ? '9+' : n
    } 

    render() {
        const { value } = this.props

        return (
            <span style={styles.bubbleAlert}>
                {this.getNumber(value)}
            </span>
        )
    }
}

export default BubbleAlert