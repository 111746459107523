import { Component } from 'react'

const styles = {
    layout: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },

    container: {
        maxWidth: '75rem',
        margin: '1rem',
    }
}

class Layout extends Component {
    render () {
        return (
            <div style={styles.layout}>
                <div style={styles.container}>
                    {this.props.children}
                    <p>Tempor in ipsum incididunt aliquip. Nostrud dolor reprehenderit sit consectetur officia ipsum sit Lorem cillum. Officia est qui elit amet ullamco mollit duis id id enim duis nisi sunt. Dolore laboris laboris aliquip labore occaecat labore incididunt non culpa excepteur. Reprehenderit ut aliqua reprehenderit anim fugiat mollit quis nulla ipsum fugiat cupidatat magna duis commodo. Veniam fugiat deserunt ea anim laboris nisi pariatur tempor Lorem voluptate consequat adipisicing sit officia. Aliqua exercitation eu consectetur fugiat ad tempor eiusmod adipisicing eiusmod esse.</p>
                </div>
            </div>
        )
    }
}

export default Layout