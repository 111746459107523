import { Component } from 'react'
import Items from './components/Items'
import Layout from './components/Layout'
import Title from './components/Title'
import Navbar from './components/Navbar'

class App extends Component {
  state = {
    items: [
      { name: 'Shirt', price: 100, img: '/items/shirt.jpg' },
      { name: 'Short', price: 50, img: '/items/short.jpg' },
      { name: 'Shoes', price: 150, img: '/items/shoes.jpg' },
    ],

    cart: [],
    
    isCartVisible: false,
  }

  addToCart = (item) => {
    const { cart } = this.state

    if (cart.find(x => x.name === item.name)) {
      const newCart = cart.map(x => x.name === item.name
        ? ({
          ...x,
          amount: x.amount + 1,
        })
        : x)
        return this.setState({cart: newCart})
    }
    
    return this.setState({
      cart: this.state.cart.concat({
        ...item, amount: 1,
      })
    })
  }

  showCart = () => {
    if (this.state.cart.length) {
      this.setState({ isCartVisible: !this.state.isCartVisible })
    }
  }

  render() {
    const { isCartVisible } = this.state
    return (
      <div>
        <Navbar
          cart={this.state.cart}
          isCartVisible={isCartVisible}
          showCart={this.showCart}
        />
        <Layout>
          <Title/>
          <Items
          addToCart={this.addToCart}
          items={this.state.items}
          />
        </Layout>
      </div>
    )
  }
}

export default App;
