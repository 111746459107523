import { Component } from 'react'
import colors from './colors'

const styles = {
    logo: {
        color: colors.primary,
        fontSize: '2rem',
        fontWeight: 'bold',
    }
}

class Logo extends Component {
    render () {
        return (
            <div style={styles.logo}>
                My Store
            </div>
        )
    }
}

export default Logo