import { Component } from 'react'
import colors from './colors'

const styles = {
    button: {
        backgroundColor: colors.primary,
        cursor: 'pointer',
        padding: '1rem 2rem',
        color: colors.white,
        textTransform: 'uppercase',
        appearance: 'none',
        border: 'none',
        fontWeight: 'bold',
    }
} 
class Button extends Component {
    render() {
        return (
            <button style={styles.button} {...this.props}/>
        )
    }
}

export default Button