import { Component } from 'react'
import colors from './colors'
import commonStyles from './commonStyles'

const styles = {
    cartDetails: {
        position: 'absolute',
        backgroundColor: colors.tertiary,
        padding: '1rem',
        boxShadow: commonStyles.boxShadow,
        width: '220px',
        right: 0,
    },

    thumbnail: {
        width: '2rem',
        height: '2rem',
        border: '.125rem solid',
        borderColor: colors.white,
    },

    items: {
        padding: 0,
        margin: 0,
    },

    item: {
        listStyle: 'none',
        padding: '0 0 1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 0 1rem',
        borderBottom: '1px solid',
        borderColor: colors.secondary,
    }
}

class CartDetails extends Component {
    render() {
        const { cart } = this.props
        return(
            <div style={styles.cartDetails}>
                <ul style={styles.items}>
                    {cart.map(x =>
                        <li style={styles.item} key={x.name}>
                            <img src={x.img} alt={x.name} style={styles.thumbnail}/>
                            {x.name}
                            <span>{x.amount}</span>
                        </li>
                    )}
                </ul>
            </div>
        )
    }
}

export default CartDetails