import { Component } from 'react'
import BubbleAlert from './BubbleAlert'
import CartDetails from './CartDetails'
import colors from './colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'

const styles = {
    cart: {
        position: 'relative',
    },

    button: {
        backgroundColor: colors.secondary,
        color: colors.white,
        cursor: 'pointer',
        padding: '1rem 2rem',
        border: 'none',
        fontSize: '2rem',
    }
}

class Cart extends Component {
    render() {
        const { cart, isCartVisible,  showCart} = this.props
        const amount = cart.reduce((acc, el) => acc + el.amount, 0)

        return (
            <div style={styles.cart}>
                <span>
                    {amount !== 0
                    ? <BubbleAlert value={amount}/>
                    : null}
                </span>
                <button
                    style={styles.button}
                    onClick={showCart}>
                    <FontAwesomeIcon icon={faCartShopping}/>
                </button>
                {isCartVisible ? <CartDetails cart={cart}/> : null}
            </div>
        )
    }
}

export default Cart