import { Component } from 'react'
import Button from './Button'
import commonStyles from './commonStyles'

const styles = {
    img: {
        maxWidth: '100%',
    },

    item: {
        boxShadow: commonStyles.boxShadow,
        flex: 'calc(calc(30rem - 100%)*999)',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
    }
}

class Item extends Component {
    render () {
        const { item, addToCart  } = this.props
        
        return (
           <div style={styles.item}>
               <img style={styles.img} alt={item.name} src={item.img}/>
               <h3>{item.name}</h3>
               <p>{item.price}</p>
               <Button onClick={() => addToCart(item)}>
                   Add to Cart
               </Button>
           </div>
        )
    }
}

export default Item